import { gql } from "@apollo/client";

export const LIST_PAYMENT_METHODS = gql`
  query listPaymentMethods($id: String) {
    listPaymentMethods(id: $id) {
      id
      card {
        display_brand
        brand
        country
        exp_month
        exp_year
        last4
      }
      sepa_debit {
        bank_code
        branch_code
        country
        last4
      }
      type
    }
  }
`;
