import { gql } from "@apollo/client";

export const QUERY_GET_PROPERTY_TYPES = gql`
  query getPropertyTypes {
    propertyTypes {
      id
      name
    }
  }
`;
