import { useLazyQuery } from "@apollo/client";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Property, RoleAction, RoleModule } from "../../__generated__/graphql";
import { LoadingPlaceholder } from "../../components/loading/placeholder";
import { Sidebar } from "../../components/sidebar/sidebar";
import { Tab } from "../../components/tab/tab";
import { QUERY_GET_PROPERTY_LIST } from "../../graphql/queries/get-property_list";
import { AppRoute } from "../../routes";
import { AuthService } from "../../services/auth.service";
import { toCurrency } from "../../utils/string.utils";
import { PropertyTypeModal } from "./components/property-types";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import { useContext } from "react";
import { NotificationContext } from "../../context/Notification";
import NotificationTopBar from "../../components/notification/notification-bar";

export function PropertyListPage() {
  const { turnNotificationsOn, hideNotificationBar } =
    useContext(NotificationContext);
  const tabs = useRef([
    {
      name: "Available",
      action: RoleAction.ListAvailable,
    },
    {
      name: "Coming Soon",
      action: RoleAction.ListAvailable,
    },
    {
      name: "New",
      action: RoleAction.ListNew,
    },
    {
      name: "Inactive",
      action: RoleAction.ListInactive,
    },
    {
      name: "Rented",
      action: RoleAction.ListRented,
    },
    {
      name: "Funded",
      action: RoleAction.ListCompleted,
    },
    {
      name: "Exited",
      action: RoleAction.ListExited,
    },
  ]);

  const navigate = useNavigate();

  const [getProperties, { loading, error, data }] = useLazyQuery(
    QUERY_GET_PROPERTY_LIST,
    { errorPolicy: "all", fetchPolicy: "no-cache" }
  );
  const [selectedTabItem, setSelectedTabItem] = useState("Available");
  const [selectedRow, setSelectedRow] = useState<number>();
  const [showTypes, setShowTypes] = useState(false);

  const handleOpenTypes = () => {
    setShowTypes(true);
  };

  const handleCloseTypes = () => {
    setShowTypes(false);
  };

  useAuthAndErrorHandling(error);

  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }

    getProperties({
      variables: {
        take: 50,
        skip: 0,
        where: { filters: getFiltersForTabItem(selectedTabItem) },
      },
    });
  }, []);

  // Navigate table using up, down and enter
  useEffect(() => {
    const handleKeyNav = (event: KeyboardEvent) => {
      if (!data) {
        return;
      }

      switch (event.key) {
        case "Enter":
          if (
            selectedRow !== undefined &&
            selectedRow >= 0 &&
            selectedRow < data.properties.length
          ) {
            selectProperty(data.properties[selectedRow].id);
          }
          break;
        case "ArrowUp":
          if (selectedRow !== undefined && selectedRow > 0) {
            setSelectedRow(selectedRow - 1);
          } else {
            setSelectedRow(data.properties.length - 1);
          }
          break;
        case "ArrowDown":
          if (
            selectedRow !== undefined &&
            selectedRow < data.properties.length - 1
          ) {
            setSelectedRow(selectedRow + 1);
          } else {
            setSelectedRow(0);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyNav);

    return () => {
      window.removeEventListener("keydown", handleKeyNav);
    };
  }, [data, selectedRow, selectProperty]);

  useEffect(() => {
    getProperties({
      variables: {
        take: 50,
        skip: 0,
        where: { filters: getFiltersForTabItem(selectedTabItem) },
      },
    });
  }, [selectedTabItem]);

  function getTabList() {
    return tabs.current
      .filter((tab) => AuthService.hasRole(RoleModule.Property, tab.action))
      .map((tab) => tab.name);
  }

  function handleTabItemClick(tabItem: string) {
    setSelectedTabItem(tabItem);
  }

  function getFiltersForTabItem(tabItem: string) {
    switch (tabItem) {
      case "Available":
        return [
          { field: "is_funded", operator: "=", value: "false" },
          { field: "status", operator: "=", value: "active" },
        ];
      case "New":
        return [{ field: "status", operator: "=", value: "pending" }];
      case "Inactive":
        return [{ field: "status", operator: "=", value: "inactive" }];
      case "Rented":
        return [{ field: "is_rented", operator: "=", value: "true" }];
      case "Funded":
        return [{ field: "is_funded", operator: "=", value: "true" }];
      case "Exited":
        return [{ field: "code", operator: "=", value: "XXXAAA" }];
      case "Coming Soon":
        return [
          { field: "status", operator: "=", value: "coming_soon" },
        ];
      default:
        return {};
    }
  }

  function selectProperty(id: string) {
    if (!AuthService.hasRole(RoleModule.Property, RoleAction.Update)) {
      return;
    }
    navigate(`${AppRoute.PropertyEdit}?id=${id}`);
  }

  return (
    <>
      <Sidebar />

      <section
        className='mt-0 content full properties sm:mt-14'
      >
        {!turnNotificationsOn && !hideNotificationBar && (
          <div className="">
            <NotificationTopBar />
          </div>
        )}
        <h1 className="mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Properties
        </h1>

        <div className="flex flex-col-reverse md:flex-row md:justify-between">
          <Tab
            items={getTabList()}
            selectedTabItem={selectedTabItem}
            loading={loading}
            handleTabItemClick={handleTabItemClick}
          />
          {AuthService.hasRole(RoleModule.Property, RoleAction.Add) && (
            <div className="flex gap-2 mb-2 md:mb-0">
              <a
                href="#"
                className="flex flex-wrap content-center justify-center w-full h-12 gap-2 px-4 py-2 transition-all duration-300 rounded-lg mb:w-auto bg-pn-blue hover:bg-pn-dark-yellow"
                onClick={() => navigate(AppRoute.PropertyEdit)}
              >
                <PlusCircleIcon width={24} className="text-white" />
                <span className="self-center text-sm font-semibold text-white">
                  Add Property
                </span>
              </a>
              <button
                className="py-4 btn btn-secondary "
                onClick={handleOpenTypes}
              >
                Types
              </button>
            </div>
          )}
          {showTypes && (
            <PropertyTypeModal
              show={showTypes}
              onShowHide={handleCloseTypes}
            />
          )}
        </div>

        <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
          <thead>
            <tr>
              <th className="px-4 py-3 border-b">Property</th>
              <th className="hidden px-4 py-3 border-b md:table-cell">Price</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Investment (#)</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Investment (GBP)</th>
              <th className="hidden px-4 py-3 border-b md:table-cell">City</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data &&
              data.properties &&
              data.properties.map((property: Property, index: number) => (
                <tr
                  key={index}
                  className={`hover:bg-pn-blue hover:text-white cursor-pointer transition-all duration-300 ${selectedRow === index
                    ? "bg-pn-dark-yellow text-pn-blue"
                    : ""
                    }`}
                  onClick={() => selectProperty(property.id)}
                >
                  <td className="px-4 py-3 text-sm">{property.title}</td>
                  <td className="hidden px-4 py-3 text-sm text-right md:table-cell">
                    {toCurrency(property.agreementPrice)}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {property.pledges.length}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {toCurrency(property.pledges.reduce((sum, pledge) => Number(sum) + Number(pledge.amount), 0))}
                  </td>
                  <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                    {property.city.name}
                  </td>
                </tr>
              ))}
            {loading && (
              <tr>
                <td colSpan={5} className="text-center">
                  <LoadingPlaceholder />
                </td>
              </tr>
            )}
            {!loading &&
              (!data || !data.properties || data.properties.length === 0) && (
                <tr>
                  <td colSpan={5} className="text-sm text-center">
                    <div className="flex flex-col items-center gap-2 p-5">
                      <InformationCircleIcon width={60} />
                      <p className="font-bold">
                        No {selectedTabItem} Properties
                      </p>
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </section>
    </>
  );
}
