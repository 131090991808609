import { useMutation } from '@apollo/client';
import { FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SvgLogo } from '../../../assets/img/common/logo.svg';
import { AppRoute } from '../../../routes';
import { GlobalErrorHandler } from '../../../components/error/global_error.component';
import { MUTATION_SEND_OTP, MUTATION_VERIFY_OTP } from '../../../graphql/mutations/user';

export function OtpPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verifyOtp, { loading, error, data }] = useMutation(MUTATION_VERIFY_OTP, { errorPolicy: 'all' });
  const [sendOtp, { loading: sendOtpLoading, error: sendOtpError, data: sendOtpData }] = useMutation(MUTATION_SEND_OTP, { errorPolicy: 'all' });
  const [deviceId, setDeviceId] = useState<string>('');

  async function resendLink() {
    setInfoMessage('Resending OTP. Please wait...');
    setErrorMessage('');
    await sendOtp({ variables: { deviceId } });
    setInfoMessage('OTP sent successfully');
    setErrorMessage('');
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const deviceIdToSet = id || localStorage.getItem('deviceId') || '';

    if (!deviceIdToSet) {
      navigate(AppRoute.AccountLogin);
    } else {
      setDeviceId(deviceIdToSet);
    }
  }, [location.search, navigate]);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    setInfoMessage('Verifying OTP. Please wait...');
    setErrorMessage('');
    const { data } = await verifyOtp({ variables: { otp: verificationCode } });
    if (data) {
      setInfoMessage('OTP verified successfully');
      setErrorMessage('');
      const role = JSON.parse(localStorage.getItem("role") || "{}");

      const hasPropertyListPermission = role?.assignments?.some(
        (assignment: { module: string; action: string }) =>
          assignment.module === "Property" && assignment.action === "List"
      );

      if (hasPropertyListPermission) {
        navigate(AppRoute.PropertyList);
      } else {
        navigate(AppRoute.Welcome);
      }
    } else {
      setErrorMessage('OTP verification failed');
      setInfoMessage('');
    }
  }

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
      if (error.message === 'Device not found') {
        navigate(AppRoute.AccountLogin);

        // Juggar: As localStorage is not giving role after login
        window.location.reload();
      }
    }
    if (sendOtpError) {
      setErrorMessage(sendOtpError.message);
    }
  }, [error, sendOtpError]);

  return (
    <section className="flex flex-col content account">
      <GlobalErrorHandler />
      <SvgLogo className='mx-auto mt-16 text-center max-w-52' />
      <h1 className='mt-24 mb-4 font-sans text-5xl font-bold leading-normal tracking-wide text-deep-blue'>Verify Your Email</h1>
      <p className='mb-8 text-center'>
        To enhance the security of your account, we need to verify your email address.
        Please enter the One-Time Password (OTP) that we've just sent to your email.
        Please check your inbox and, if necessary, your spam or junk folder.
      </p>
      <form className='w-1/2 max-w-md p-8 mx-auto bg-white rounded-lg shadow-md card' onSubmit={onSubmit}>
        <div className="flex flex-col gap-2 p-4">
          <label className='mb-2 -mt-2 text-base font-bold tracking-normal'>Verification code</label>
          <input
            className='box-border w-full p-4 text-base border border-gray-300 rounded'
            autoFocus
            type="text"
            value={verificationCode}
            placeholder='Enter your verification code'
            onChange={e => setVerificationCode(e.target.value)}
          />
        </div>
        {infoMessage && <div className="px-12 py-6 mb-12 border rounded-lg bg-light-grey">{infoMessage}</div>}
        {errorMessage && <div className="px-12 py-6 mb-12 text-red-500 border rounded-lg bg-light-grey -600">{errorMessage}</div>}
        <div className="flex flex-wrap justify-center gap-2 p-4">
          <button type="submit" className='btn btn-primary' style={{ height: '3rem' }}>Verify</button>
          <button className='btn-secondary btn' type="button" onClick={resendLink} style={{ height: '3rem' }}>Resend link</button>
        </div>
      </form>
    </section>
  );
}
