import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { AppRoutes } from './routes';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import './assets/styles/index.css';
import { AuthService } from './services/auth.service';
import { messaging } from './config/firebase.config';
import { onMessage } from 'firebase/messaging';
import { NotificationProvider } from './context/Notification';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/admin`,
});

const authLink = setContext((_, { headers }) => {
  const token = AuthService.getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.trim()}` : "",
    }
  }
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <NotificationProvider>
        <AppRoutes />
      </NotificationProvider>
    </ApolloProvider>
  </React.StrictMode >
);

// Register service worker and initialize Firebase Messaging
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('ServiceWorker registration successful with scope: ', registration.scope);

        // Listen for messages when the app is in the foreground
        onMessage(messaging, (payload) => {
          console.log('Message received. ', payload);
          // Show notification or update UI as needed
        });

      }).catch((error) => {
        console.error('ServiceWorker registration failed: ', error);
      });
  });
} else {
  console.warn('Service workers are not supported in this browser.');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
