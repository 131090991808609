import React, { useEffect, useState } from 'react';

type SnackbarProps = {
    message: string;
    open: boolean;
    severity?: 'success' | 'error' | 'warning' | 'info';
    duration?: number;
    onClose: () => void;
    position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'top-center' | 'bottom-center';
    customStyles?: React.CSSProperties; 
    autoClose?: boolean; 
};

const Snackbar: React.FC<SnackbarProps> = ({
    message,
    open,
    severity = 'info',
    duration = 3000,
    onClose,
    position = 'bottom-center',
    customStyles = {},
    autoClose = true,
}) => {
    const [visible, setVisible] = useState(open);

    useEffect(() => {
        if (open) {
            setVisible(true);
            if (autoClose) {
                const timer = setTimeout(() => {
                    setVisible(false);
                    onClose();
                }, duration);
                return () => clearTimeout(timer);
            }
        }
    }, [open, duration, autoClose, onClose]);

    if (!visible) return null;

    let bgColor = 'bg-pn-blue'; 
    if (severity === 'success') bgColor = 'bg-green-500';
    if (severity === 'error') bgColor = 'bg-red-500';
    if (severity === 'warning') bgColor = 'bg-yellow-500';

    const positionClass = {
        'top-left': 'top-4 left-4',
        'top-right': 'top-4 right-4',
        'bottom-left': 'bottom-4 left-4',
        'bottom-right': 'bottom-4 right-4',
        'top-center': 'top-4 left-1/2 transform -translate-x-1/2',
        'bottom-center': 'bottom-4 left-1/2 transform -translate-x-1/2',
    }[position];

    const animationClass = visible
        ? 'opacity-100 transition-opacity duration-500 ease-in'
        : 'opacity-0 transition-opacity duration-500 ease-out';

    const getIcon = (severity: string) => {
        switch (severity) {
            case 'success':
                return <i className="pr-3 text-2xl lni lni-checkmark-circle" />; 
            case 'error':
                return <i className="pr-3 text-2xl lni lni-cross-circle" />;    
            case 'warning':
                return <i className="pr-3 text-2xl lni lni-alarm-warning" />;   
            case 'info':
            default:
                return <i className="pr-3 text-2xl lni lni-information" />;     
        }
    };

    return (
        <div
            style={customStyles}
            className={`${positionClass} ${animationClass} fixed p-4 rounded-md shadow-lg text-white ${bgColor} z-50 flex items-center justify-center`}
        >
            
            {getIcon(severity)}
            <span className='text-xl !text-white'>
                {message}
            </span>

            {!autoClose && (
                <button onClick={() => { setVisible(false); onClose(); }} className="ml-4 text-sm underline">
                    Close
                </button>
            )}
        </div>
    );
};

export default Snackbar;
