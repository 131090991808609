import { Sidebar } from "../../components/sidebar/sidebar";

export function WelcomePage() {
    return (
        <>
            <Sidebar />
            <section className="mt-0 content full sm:mt-14">
                <h1 className="mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    PropNerd Admin
                </h1>

                <p className="mb-4 text-gray-600">
                    Everything you need to manage and monitor is just a few clicks away.
                </p>

                <p className="mb-4 text-gray-600">
                    Use the sidebar to navigate to the relevant section.
                </p>
            </section>
        </>
    );
}
